import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, SilentRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, Subscription, filter, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>();
  
  subscription: Subscription = new Subscription();

  loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loggedIn$: Observable<boolean> = this.loggedIn.asObservable();

  constructor(
    private authService: MsalService,
    private broadcastService: MsalBroadcastService
  ) {}

  signInInit(): void {
    this.signInSuccess();
    this.signInFailure();
    if(this.existsAccount()) {
      this.loggedIn.next(true);
    }
  }

  signInSuccess(): void {
    this.subscription = this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    ).subscribe((r: EventMessage) => {
      this.loggedIn.next(true);
      this.authService.instance.getActiveAccount();
    });
  }

  signInFailure(): void {
    this.broadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      takeUntil(this._destroying$)
    ).subscribe((r: EventMessage) => {
      this.signOut();
    });
  }

  acquireTokenSilent() {
    if (this.existsAccount()) {
      this.authService.acquireTokenSilent(this.getRequest()).subscribe();
    }
  }

  signOut() {
    this.signOutFinish();
  }

  signOutFinish() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    sessionStorage.clear();
    localStorage.clear();
    this.authService.logout();
    this.authService.logoutRedirect();
  }

  getRequest(): SilentRequest {
    return {
      scopes: ['openid', 'profile', 'offline_access'],
      account: this.getUserAccount()
    } as SilentRequest
  }

  signIn(): void {
    sessionStorage.clear();
    localStorage.clear();  
    if(sessionStorage.getItem('msaletre')) {
      sessionStorage.setItem('msuset', 'true');
    }
    this.authService.loginRedirect();
  }

  existsAccount(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  getUserAccount(): unknown {
    return this.existsAccount() ? this.authService.instance.getAllAccounts()[0] : null;
  }

  getUserFullName(): string {
    let res: string | undefined = '';
    if(this.existsAccount()) {
      res = this.authService.instance.getAllAccounts()[0].name;
      res = res? res : this.getUserName();
    }
    return res;
  }

  getUserName(): string {
    let res = '';
    if(this.existsAccount()) {
      res = this.authService.instance.getAllAccounts()[0].username?.split('@')[0];
    }
    return res;
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
