import { BrowserCacheLocation, IPublicClientApplication, InteractionType, 
  LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AUTHORITY, BACKEND_HOST, CLIENT_ID, FRONTEND_HOST, GRAPH_SCOPE, GRAPH_URL } from "./config";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string, piiEnabled: boolean) {
  // This is intentional
}

export function cMSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: CLIENT_ID,
      authority: AUTHORITY,
      redirectUri: FRONTEND_HOST,
      postLogoutRedirectUri: FRONTEND_HOST
    },
      cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
  
export function cMSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${GRAPH_URL}`, [GRAPH_SCOPE]);
  protectedResourceMap.set(BACKEND_HOST, [`${CLIENT_ID}/openid`]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function cMSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...GRAPH_SCOPE]
    },
    loginFailedRoute: '/'
  };
}
  