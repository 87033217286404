import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, finalize } from 'rxjs';
import { LoadingService } from 'src/app/shared/services/loading/loading.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { HTTP_INTERNAL_ERROR_CODE, HTTP_NOT_CONNECTION_CODE, HTTP_NOT_FOUND_CODE, HTTP_UNAUTHORIZED_CODE } from 'src/app/shared/constants/core.constants';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {

  private requestCount: number = 0;
  constructor(
    private loadingService: LoadingService,
    private alertService: AlertService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    this.loadingService.show("Procesando petición...");
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err) {
          this.customCatchError(err);
        }
        throw err;
      }),
      finalize(() => {
        this.requestCount--;
        if(this.requestCount == 0) {
          this.loadingService.hide();
        }
      })
    );
  }

  customCatchError(err: HttpErrorResponse): void {
    switch (err.status) {
      case HTTP_INTERNAL_ERROR_CODE:
        this.alertService.error(err.error?.detail);
        break;
      case HTTP_NOT_FOUND_CODE:
        this.alertService.error("El servicio que intentas consultar no existe.");
        break;  
      case HTTP_NOT_CONNECTION_CODE:
        this.alertService.error("Por favor verifica tu conexión a internet y/o VPN Bancolombia y vuelve a intentarlo.");
        break;
      case HTTP_UNAUTHORIZED_CODE:
        const ep: string = err?.url?.substring(err?.url?.indexOf("/auditoria-virtual/")) || "este recurso"
        this.alertService.error("No tienes permisos sobre " + ep + ", por favor contacta al equipo de soporte.");
        break;
      default:
        this.alertService.error("Ha ocurrido un error inesperado, por favor contacta al equipo de soporte.");
        break;
    }
  }
}
