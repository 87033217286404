import { environment } from "../../environments/environment";

export const AUTH_URL = "https://login.microsoftonline.com";
export const GRAPH_SCOPE = 'user.read';
export const GRAPH_URL = "https://graph.microsoft.com/v1.0/me";
export const ENV = environment.env;
export const FRONTEND_HOST = environment.frontEndUrl;
export const BACKEND_HOST = environment.backEndUrl;
export const BACKEND_HOST_MAIN = `${BACKEND_HOST}/auditoria-virtual-mig-main`;
export const CLIENT_ID = environment.clientId; 
export const OBJECT_ID = environment.objectId; 
export const AUTHORITY = `${AUTH_URL}/${environment.tenantId}`;
export const L_SDB_URL = "https://library-sdb.apps.bancolombia.com/bds/7.0.0";    