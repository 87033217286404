import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BcCircleLoadingComponent } from '@bancolombia/design-system-web/bc-circle-loading';
import { LoadingService } from '../../services/loading/loading.service';
import { TIMEOUT_HIDE_LOADING } from '../../constants/shared.constants';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

  @ViewChild(BcCircleLoadingComponent, { static: false }) bcCircleLoading: BcCircleLoadingComponent;  
  
  circleLoadingText: string;
  circleLoadingAriaLabel: string = "Procesando petición...";
  loaderSpeed: string = "0.5";

  constructor(
    private loadingService: LoadingService,
    private cdRef:ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.loadingService.loadingCtrl$.subscribe(r => {
      if(r?.isLoading) {
        this.show(r.circleLoadingText, r.isAutomaticClosing);
      } else {
        this.bcCircleLoading.hideCircleLoading();
      }
    });
    this.cdRef.detectChanges();
  }

  show(circleLoadingText?: string, isAutomaticClosing?: boolean): void {
    this.circleLoadingText = circleLoadingText;
    this.bcCircleLoading.showCircleLoading();
    if(isAutomaticClosing) {
      setTimeout(() => {
        this.bcCircleLoading.hideCircleLoading();
      }, TIMEOUT_HIDE_LOADING);
    }
  }
}
