import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingCtrl = new BehaviorSubject<any>({
    isLoading: false,
    circleLoadingText: '',
    isAutomaticClosing: false
  });
  loadingCtrl$ = this.loadingCtrl.asObservable();

  show(circleLoadingText?: string, isAutomaticClosing?: boolean) {
    this.loadingCtrl.next({
      isLoading: true,
      circleLoadingText,
      isAutomaticClosing
    });
  }

  hide() {
    this.loadingCtrl.next({
      isLoading: false,
      circleLoadingText: '',
      isAutomaticClosing: false
    });
  }
}
