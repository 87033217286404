import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BcHelperService } from "@bancolombia/design-system-web/bc-helpers";
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { cMSALGuardConfigFactory, cMSALInstanceFactory, 
  cMSALInterceptorConfigFactory } from './config/msal.config';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SessionInterceptor } from './core/interceptors/session/session.interceptor';
import { BcAlertModule } from '@bancolombia/design-system-web/bc-alert';
import { registerLocaleData } from '@angular/common';
import localEsCO  from '@angular/common/locales/es-CO';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { BcCircleLoadingModule } from '@bancolombia/design-system-web/bc-circle-loading';

registerLocaleData(localEsCO);

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule.forRoot(cMSALInstanceFactory(), cMSALGuardConfigFactory(), cMSALInterceptorConfigFactory()),
    BcAlertModule,
    BcCircleLoadingModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-CO'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    BcHelperService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
