import { Component, ElementRef, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { SessionService } from './core/services/session/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  constructor(
    private _elementRef: ElementRef,
    private authService: MsalService,
    private sessionService:SessionService
  ) {}

  ngOnInit(): void {
    this.sessionService.signInInit();
    this._elementRef.nativeElement.removeAttribute("ng-version");
    this.authService.handleRedirectObservable().subscribe({
      next: (r: AuthenticationResult) => {
        document.getElementById("redirect")?.removeAttribute("ng-version");
      }
    });
  }
}
