export const TIMEOUT_HIDE_LOADING = 5000;
export const TIMEOUT_TOGGLE_EVENT = 50;
export const EMPTY_STRING = "";
export const DEFAULT_SIZE_PER_PAGE = 5;
export const DEFAULT_SIZE_PER_PAGE_NT = 10000;
export const TIMEOUT_SEARCH_DETECT = 100;
export const TIMEOUT_COMPLETE_FILTER_BY_KEY = 200;
export const MSG_EXCEPTION_SEARCH = "¡Error controlado!, evita que se dispare la busqueda al seleccionar el tipo de filtro en modulo";
export const LIMIT_CELL_STATIC = 31;
export const LIMIT_CELL_STATIC_SPLIT = 28;
export const TREE_PERCENT_DROP_ABOVE = 0.25;
export const TREE_PERCENT_DROP_BELOW = 0.75;
export const TREE_DRAG_NODE_EXPAND_OVER_WAIT_MS = 300;
export const MAX_LENGTH_STRING_INPUT_L = 200;
export const MAX_LENGTH_STRING_TEXTAREA_L = 2000;
export const MAX_LENGTH_TREE_EXPAND = 4
