import { Injectable } from '@angular/core';
import { BcDialogService } from '@bancolombia/design-system-web/bc-services'
import { BcAlertComponent } from '@bancolombia/design-system-web/bc-alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private dialogService: BcDialogService) {}

  success(text: string): void {
    this.open('success', text);
  }

  error(text: string): void {
    this.open('error', text);
  }
  
  info(text: string): void {
    this.open('info', text);
  }

  warning(text: string): void {
    this.open('warning', text);
  }
  
  inactive(text: string): void {
    this.open('inactive', text);
  }

  open(type: string, text: string, elementRef?: string): void {
    this.dialogService.open(BcAlertComponent, {
      id: 'AuditoriaVirtualAlertId',
      type,
      text,
      insertBefore: true,
      elementRef: elementRef? elementRef : null,
      timeout: 8000,
    });
  }
}
